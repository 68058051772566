<template>
	<div class="modal fade whats-new" id="WhatsNew" tabindex="-1" role="dialog" aria-labelledby="exampleModalLabel"
		aria-hidden="true" data-backdrop="static">
		<div class="modal-dialog" role="document">
			<div class="modal-content">
				<button type="button" class="close" aria-label="Close" @click="closeWhatsNewModal()">
					<span aria-hidden="true">&times;</span>
				</button>
				<div class="modal-body p-0" >
					<img :src="whats_new_customer" class="d-block img-fluid w-100" v-if="whats_new_customer">
				</div>
			</div>
		</div>
	</div>
</template>
  
<script>
import localconfig from "../../global_settings.json";
export default {
	name: 'WhatsNew',
	data: function () {
		return {
			global_settings: [],
			whats_new_customer: "",
		}
	},
	mounted() {
		// if (localStorage.getItem("global_settings") == null) {
			this.getGlobalSettings();
		// } else {
		// 	this.globalsettings = JSON.parse(localStorage.getItem("global_settings"));
		// 	this.whats_new_customer = this.globalsettings.whats_new_customer;
		// 	if (this.whats_new_customer != '' && document.cookie.indexOf('whats_new=' + this.whats_new_customer) == -1) {
		// 		window.$("#WhatsNew").modal("show");
		// 	}
		// 	console.log(this.whats_new_customer,"line no 37");
		// }

	},
	methods: {
		closeWhatsNewModal() {
			var year = 1000 * 60 * 60 * 24 * 365;
			var expires = new Date((new Date()).valueOf() + year);
			document.cookie = "whats_new=" + this.whats_new_customer + ";expires=" + expires.toUTCString();
			window.$("#WhatsNew").modal("hide");
			localStorage.setItem('visited', 0);
		},
		async getGlobalSettings() {
			return this.$store.dispatch("getglobalsetng").then(response => {
				if (response.status == 1) {
					this.globalsettings = response.data;
					this.whats_new_customer = this.globalsettings.whats_new_customer;
					if(this.whats_new_customer != null){
						if (this.whats_new_customer != '' && document.cookie.indexOf('whats_new=' + this.whats_new_customer) == -1) {
						window.$("#WhatsNew").modal("show");
						}
					}
					
					console.log(this.whats_new_customer,"line no 56");
				} else {
					this.whats_new_customer = localconfig.whats_new_customer;
				}
			})
		}
	}
}
</script>
  
<style>
#WhatsNew {
	z-index: 9999;
}
</style>