<template>
<div>
     <div
            class="modal fade repeat-alert-modal"
            id="StoreChangeConfirmationModal"
            tabindex="-1"
            role="dialog"
            aria-labelledby="StoreChangeConfirmationModal"
            aria-hidden="true"
            style="z-index:99999;margin-top:85px"
          >
            <div class="modal-dialog" role="document">
              <div class="modal-content">
                <div class="modal-header">
                  <h5 class="modal-title" id="RepeatAlertModalLabel" >
                      <p>Load the new store?</p>
                  </h5>
                  <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                  </button>
                </div>
                <div class="modal-body">
                    We have a different store delivering to selected location.Do you wish to load the new store?
                </div>
                <div class="modal-footer">
                  <button type="button" data-dismiss="modal" class="btn btn-secondary" >No</button>
                  <button type="button" :style="colorObj" class="btn btn-primary hoverEffect" @click="redirectToHomePage()">Yes</button>
                </div>
              </div>
            </div>
          </div>
  <header class="header">
        
    <nav :class="(current_route_name == 'shopdetail' && !isMobile()) ? 'navbar navbar-expand-md bg-white navbar-light position-fixed' : 'navbar navbar-expand-md bg-white navbar-light position-fixed'">
      <div class="container">
        
        <a href="/" class="navbar-brand d-none d-md-block" v-if="globalsettings.length == 0">
          <img :src="brand_logo" />
        </a>
        <a href="/" class="navbar-brand d-none d-md-block" v-else>
          <img :src="globalsettings.logo" />
        </a>

        <div class="nav-user-icon d-flex">
          <button class="navbar-toggler" type="button" v-b-toggle.navbarSupportedContent>
            <span class="line"></span>
            <span class="line"></span>
            <span class="line"></span>
          </button>
          <div class="user-icon d-md-none">
            <div class="profile-id media" v-if="userprofile==null || userprofile==false">
              <a class="order-1 order-md-2" href="javascript:void(0)" @click="openLoginfrm">
                <img src="../assets/images/svgs/user.svg" alt="user" class="default-user ml-3" />
              </a>
            </div>
            <div class="profile-id media" v-else>
              <a href="/profile#profile" class="order-1 order-md-2">
                <img v-if="userprofile.user_picture != ''" :src="userprofile.user_picture" class="ml-3 rounded-circle" />
                <avatar v-else-if="userprofile.name != ''" :fullname="(userprofile.name.split(' ')[0] + ' ' +userprofile.name.split(' ')[1])" :size="30" class="ml-3 rounded-circle align-middle" :color="avatarBackgroundColor"></avatar>
              </a>
            </div>
          </div>
        </div>


        <b-collapse class="navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto login-row justify-content-start">
              <li class="newsletter">
                <!-- <a :href="" target="_blank"> -->
                  <button class= "news-letter hoverEffect" @click="openNewsLetter()"> Sign Up For Newsletter </button>
                <!-- </a> -->
              </li>
              <li class="app-store d-none d-md-block">
                <ul class="list-unstyled d-flex align-items-center">
                  <li>
                    <a :href="iosappurl" class="icon" target="_blank">
                      <img src="../assets/images/svgs/apple.svg" alt="apple">
                    </a>
                  </li>
                  <li>
                    <a :href="androidappurl" class="icon" target="_blank">
                      <img src="../assets/images/svgs/android.svg" alt="android">
                    </a>
                  </li>
                </ul>
              </li>
              <li class="nav-item d-none d-md-block" v-if="userprofile==null || userprofile==false">
                <a class="btn" href="javascript:void(0)" id="SignupBtn" @click="openLoginfrm">
                  <img src="../assets/images/svgs/user.svg" alt="user" />
                </a>
              </li>
              <li class="user-profile d-md-none">
                <div class="login-user-profile d-flex justify-content-between align-items-center">
                  <div class="profile-id media" v-if="userprofile!=null  && userprofile!=false">
                    <div class="media-body order-2 order-md-1">
                      <a href="/profile#profile"><h5 class="user-name">{{userprofile.name}}</h5></a>
                      <div class="add-selector" v-if="getaddressdetails.status=='1'">
                        <select
                          class="form-control"
                          name="seldefltaddrs"
                          id="seldefltaddrs"
                          v-model="seldefltaddrs"
                          @change="Deliveraddrs($event)"
                        >
                          <option
                            v-for="(addrsdetl,index) in getaddressdetails.data"
                            :value="addrsdetl.customer_address_id"
                            v-bind:key="index"
                          >{{getFilteredUserAddress(addrsdetl)}}</option>
                        </select>
                      </div>
                    </div>
                    <a href="/profile#profile" class="order-1 order-md-2">
                      <img v-if="userprofile.user_picture != ''" :src="userprofile.user_picture" class="ml-3 rounded-circle" />
                      <avatar v-else :fullname="(userprofile.name.split(' ')[0] + ' '+userprofile.name.split(' ')[1])" :size="48" class="ml-3 rounded-circle" :color="avatarBackgroundColor"></avatar>
                    </a>
                  </div>
                  <div v-else class="profile-id media">
                    <a class="btn" href="javascript:void(0)" id="SignupBtn" @click="openLoginfrm">
                      <img src="../assets/images/svgs/user.svg" alt="user" />
                    </a>
                    <div class="media-body">
                      <a class="btn" href="javascript:void(0)" id="SignupBtn" @click="openLoginfrm">
                        <h5 class="user-name">Sign In / Sign Up</h5>
                      </a>
                    </div>
                  </div>
                  <div class="notification-icon">
                    <a href="javascript:void(0)" @click="openNotification()">
                      <img src="../assets/images/profile-icons/bell.svg" alt="bell">
                    </a>
                  </div>
                </div>
                <div class="profile-menu-main">
                    <div class="row">
                      <div class="col-4">
                        <a href="javascript:void(0)" @click="openHomePage()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/home.svg" alt="home">
                              </div>
                              <h5 class="profile-menu-name">Home</h5>
                          </div>
                        </a>
                      </div>
                      
                      <div class="col-4" v-if="is_business_model != 3">
                        <a href="javascript:void(0)" @click="openFavoriteStore()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/favorite.svg" alt="home">
                              </div>
                              <h5 class="profile-menu-name">Favorites</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4">
                        <a href="javascript:void(0)" @click="openAddressBook()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/address-book.svg" alt="address-book">
                              </div>
                              <h5 class="profile-menu-name">Address Book</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4">
                        <a href="javascript:void(0)" @click="openMyOrder()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/my-order.svg" alt="my-order">
                              </div>
                              <h5 class="profile-menu-name">My Orders</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4" v-if="globalsettings.chat_module == 1">
                        <a href="javascript:void(0)" @click="openChat()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/chat.svg" alt="chat">
                              </div>
                              <h5 class="profile-menu-name">Chat Support</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4" v-if="globalsettings.allow_khata == 1">
                        <a href="javascript:void(0)" @click="openKhata()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/my-khata.svg" alt="Khata">
                              </div>
                              <h5 class="profile-menu-name">{{ globalsettings.khata_label }}</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4" v-if="globalsettings.allow_wallet == 1">
                        <a href="javascript:void(0);" @click="openWallet()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/ic_my_wallet.svg" alt="wallet">
                              </div>
                              <h5 class="profile-menu-name">Wallet</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4" v-if="globalsettings.wishlist == 1">
                        <a href="javascript:void(0);" @click="openWishlist()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/wishlist.svg" alt="wishlist">
                              </div>
                              <h5 class="profile-menu-name">Wishlist</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4" v-if="globalsettings.giftcard_module == 1">
                        <a href="javascript:void(0);" @click="openGiftVoucher()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/ic_gift_voucher.svg" alt="wishlist">
                              </div>
                              <h5 class="profile-menu-name">Gift Voucher</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4" v-if="is_business_model == 1">
                        <a href="https://business.lokaly.in/register" target="_new">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/seller.svg" alt="Seller Registration">
                              </div>
                              <h5 class="profile-menu-name">Sell on Lokaly</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4">
                        <a href="javascript:void(0)" @click="openContactUs()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/contact-us.svg" alt="Contact Us">
                              </div>
                              <h5 class="profile-menu-name">Contact Us</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4" v-if="is_business_model == 1">
                        <a href="https://business.lokaly.in/privacy-policy" target="_new">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/privacy-policy.svg" alt="Privacy Policy">
                              </div>
                              <h5 class="profile-menu-name">Privacy Policy</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4">
                        <a href="https://business.lokaly.in/terms-of-use" target="_new" v-if="is_business_model == 1">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/tou.svg" alt="Terms of Use">
                              </div>
                              <h5 class="profile-menu-name">Terms of Use</h5>
                          </div>
                        </a>
                        <router-link to="/tou" v-else>
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/tou.svg" alt="Terms of Use">
                              </div>
                              <h5 class="profile-menu-name">Terms of Use</h5>
                          </div>
                        </router-link>
                      </div>
                      <div class="col-4" v-if="is_business_model == 1">
                        <a href="https://business.lokaly.in/disclaimer" target="_new">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/disclaimer.svg" alt="Disclaimer">
                              </div>
                              <h5 class="profile-menu-name">Disclaimer</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4">
                        <a href="javascript:void(0)" @click="openModal()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/ic_whats_new.svg" alt="home">
                              </div>
                              <h5 class="profile-menu-name">What's New</h5>
                          </div>
                        </a>
                      </div>
                      
                      <div class="col-4">
                        <a href="javascript:void(0)" @click="openNewsLetter()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/news-letter.svg" alt="home">
                              </div>
                              <h5 class="profile-menu-name">Newsletter</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4">
                        <a href="https://business.lokaly.in/" target="_new" v-if="is_business_model == 1">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/about.svg" alt="about">
                              </div>
                              <h5 class="profile-menu-name">About</h5>
                          </div>
                        </a>
                        <router-link to="/about-us" v-else>
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/about.svg" alt="about">
                              </div>
                              <h5 class="profile-menu-name">About</h5>
                          </div>
                        </router-link>
                      </div>
                      
                      <div class="col-4">
                        <a href="javascript:void(0)" @click="clickFaq()">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/ic_faq.svg" alt="home">
                              </div>
                              <h5 class="profile-menu-name">FAQ</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4">
                        <router-link to="/return-policy">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/return-policy.svg" alt="return policy">
                              </div>
                              <h5 class="profile-menu-name">Return Policy</h5>
                          </div>
                        </router-link>
                      </div>
                      <div class="col-4">
                        <router-link to="/return-center">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/return-center.svg" alt="return center">
                              </div>
                              <h5 class="profile-menu-name">GB Return Center</h5>
                          </div>
                        </router-link>
                      </div>
                      <div class="col-4">
                        <router-link to="/UniversityProgram">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/studentdiscount-icon.svg" alt="student-discount-program">
                              </div>
                              <h5 class="profile-menu-name">Student Discount Program</h5>
                          </div>
                        </router-link>
                      </div>
                      <div class="col-4">
                        <router-link to="/feedback">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/feedback.svg" alt="apple">
                              </div>
                              <h5 class="profile-menu-name">Feedback</h5>
                          </div>
                        </router-link>
                      </div>
                      <div class="col-4">
                        <a :href="iosappurl" target="_blank">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/app-icon-01.svg" alt="apple">
                              </div>
                              <h5 class="profile-menu-name">App Store</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4">
                        <a :href="androidappurl" target="_blank">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/app-icon-02.svg" alt="android">
                              </div>
                              <h5 class="profile-menu-name">Google Play</h5>
                          </div>
                        </a>
                      </div>
                      <div class="col-4" v-if="userprofile!=null  && userprofile!=false">
                        <a href="javascript:void(0)" @click="logout">
                          <div class="profile-menu-box">
                              <div class="profile-menu-img">
                                <img src="../assets/images/profile-icons/log-out.svg" alt="log-out">
                              </div>
                              <h5 class="profile-menu-name">Log out</h5>
                          </div>
                        </a>
                      </div>
                    </div>
                </div>
                <div class="profile-menu-main profile-bottom">
                    <div class="row">
                      <div class="col-12 text-center">
                        <a href="/" class="brand-logo" v-if="globalsettings.length == 0">
                          <img :src="brand_logo"/>
                        </a>
                        <a href="/" class="brand-logo" v-else>
                          <img :src="globalsettings.logo"/>
                        </a>
                      </div>
                      <!-- <div class="col-12 text-center">
                        <div class="links">
                          <div class="social-bar">
                            <h2 class="section-heading">Follow us</h2>
                            <ul class="social-media-links row list-unstyled mx-0 d-flex align-items-center justify-content-center">
                              <li>
                                <a href="https://www.facebook.com/lokaly.in/" target="_blank">
                                  <img src="../assets/images/social-icons/facebook.svg" alt="facebook">
                                </a>
                              </li>
                              <li>
                                <a href="https://www.instagram.com/lokalybiz/" target="_blank">
                                  <img src="../assets/images/social-icons/instagram.svg" alt="instagram">
                                </a>
                              </li>
                              <li>
                                <a href="https://twitter.com/LokalyBiz" target="_blank">
                                  <img src="../assets/images/social-icons/twitter.svg" alt="twitter">
                                </a>
                              </li>
                              <li>
                                <a href="https://www.linkedin.com/company/lokalybiz/" target="_blank">
                                  <img src="../assets/images/social-icons/linkedin.svg" alt="linkedin">
                                </a>
                              </li>
                              <li>
                                <a href="https://in.pinterest.com/lokalybiz/" target="_blank">
                                  <img src="../assets/images/social-icons/pintres.svg" alt="pintrest">
                                </a>
                              </li>
                              <li>
                                <a href="https://www.youtube.com/channel/UCWebVyrMSUQk2_NxCM8Uawg/videos" target="_blank">
                                  <img src="../assets/images/social-icons/youtube.svg" alt="youtube">
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div> -->
                    </div>
                </div>
              </li>
            </ul>
        </b-collapse>
        
        <div class="user-info d-flex flex-wrap align-items-center">
          <div class="user-profile" v-if="userprofile!=null  && userprofile!=false">
            <div class="profile-id media">
              <div class="media-body">
                <h5>{{userprofile.name}}</h5>
                <div class="add-selector" v-if="getaddressdetails.status=='1'">
                  <select
                    class="form-control"
                    name="seldefltaddrs"
                    id="seldefltaddrs"
                    v-model="seldefltaddrs"
                    @change="Deliveraddrs($event)"
                  >
                    <option
                      v-for="(addrsdetl,index) in getaddressdetails.data"
                      :value="addrsdetl.customer_address_id"
                      v-bind:key="index"
                    >{{getFilteredUserAddress(addrsdetl)}}</option>
                  </select>
                </div>
              </div>
              <div class="profile dropdown">
                <a
                  class="nav-link dropdown-toggle"
                  href="javascript:void(0)"
                  id="navbarDropdown"
                  role="button"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <img v-if="userprofile.user_picture != ''" :src="userprofile.user_picture" class="rounded-circle" />
                  <avatar v-else :fullname="(userprofile.name.split(' ')[0] + ' ' +userprofile.name.split(' ')[1])" :size="48" class="rounded-circle" :color="avatarBackgroundColor"></avatar>
                </a>
                <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                  <a class="dropdown-item" href="javascript:void(0)" @click="headerMenuProfileNavigate('/profile#profile')" >Profile</a>
                  <router-link to="/search#favorite-store" v-if="is_business_model!=3" class="dropdown-item">Favorites</router-link>
                  <router-link to="/profile#address-book" class="dropdown-item">Address Book</router-link>
                  <router-link to="/profile#my-order" class="dropdown-item">My Orders</router-link>
                  <router-link to="/chat" class="dropdown-item" v-if="globalsettings.chat_module == 1">Chat Support</router-link>
                  <router-link to="/profile#my-khata" class="dropdown-item" v-if="globalsettings.allow_khata == 1">{{ globalsettings.khata_label }}</router-link>
                  <router-link to="/profile#wallet" class="dropdown-item" v-if="globalsettings.allow_wallet == 1">Wallet</router-link>
                  <router-link to="/profile#wishlist" class="dropdown-item" v-if="globalsettings.wishlist == 1">Wishlist</router-link>
                  <a class="dropdown-item" v-show="globalsettings.giftcard_module == 1" href="javascript:void(0)" @click="openGiftVoucher">Gift Voucher</a>
                  <router-link to="/profile#notification" class="dropdown-item">Notifications</router-link>
                  <a class="dropdown-item" href="https://business.lokaly.in/" target="_new" v-if="is_business_model == 1">About</a>
                  <router-link to="/about-us" class="dropdown-item" v-else>About</router-link>
                  <a href="javascript:void(0)" @click="openContactUs" class="dropdown-item" >Contact</a>
                  <a class="dropdown-item" href="javascript:void(0)" @click="logout">Logout</a>
                </div>
              </div>
            </div>
          </div>
          <div class="user-profile login-location" v-else>
            <div class="profile-id media">
              <div class="media-body">
                <h5></h5>
                <div class="add-selector">
                  <a class="text-black" href="#" data-toggle="modal" data-target="#LocationSelector">
                    <span>{{address | formatAddress}}</span>
                  </a>
                </div>
              </div>
            </div>
          </div>
          <div class="location-selector">
            <a class="popup-btn" href="#" type="button" data-toggle="modal" data-target="#LocationSelector"></a>
          </div>
        </div>
        <div class="head-search order-md-0 order-first ml-md-3 ml-auto position-relative">
          <a href="javascript:void(0)" v-b-toggle.Togglesearchbar @click="searchToggle">
              <img src="../assets/images/svgs/search.svg" />
          </a>
        </div>
        <div class="cart">
          <a href="javascript:void(0)" @click="getCartDetails()" class="btn">
            <span class="icon">
              <span v-if="getcartlngth != 0" class="notification">{{getcartlngth}}</span>
              <img src="../assets/images/svgs/cart.svg" alt="cart" />
            </span>
          </a>
        </div>
      </div>
    </nav>
    <b-collapse class="taggle-search" id="Togglesearchbar">
        <form class="form-inline shop-searchbar" @submit.prevent="postSearchnew">
            <div class="form-group col-12">
              <label for="searchbar" class="sr-only">search</label>
              <input type="text" class="form-control" id="searchbar" :placeholder="(is_business_model === 3) ? 'Search Bazaar' : 'Search for item / Category / Store'" autocomplete="nope" @keyup="searchAutoComplete" @focus="modal = true" ref="header_search" v-model="searchqry">
            </div>
            <div class="auto-complete" v-if="auto_complete.length > 0 && modal && searchqry!=''">
              <ul class="auto-suggestion-dropdown">
                <li class="py-2 ml-3" @click="setState(result)" style="cursor:pointer;" :key="index" v-for="(result,index) in auto_complete">{{result.item_name}}</li>
              </ul>
            </div>    
        </form>
    </b-collapse>
  </header>
  </div>
</template>
<script>
import Avatar from "vue-avatar-component";
import { mapGetters } from "vuex";
import { bus } from "../main";
import { func } from "../store/func";
import localconfig from "../global_settings.json"
import helper from "../store/helper";
import firebase from 'firebase/app';
import { appversion } from "../store/actions";
//import refreshapp from "../store/refreshapp";
export default {
  name: "Header",
  props: {
    loading: {
      type: Boolean
    },
    userprofile: null,
  },
  mixins:[helper],
  components: {
    Avatar
  }, 
  data() {
    return {
      avatarBackgroundColor:"",
      avatarTextColor:"",
      logoutbody: {
        phone: "",
        fcm_token:""
      },
      profile: {
        user_id: ""
      },
      seldefltaddrs: "",
      getaddressstatus: "",
      getaddressdetails: [],
      newd: "",
      globalsettings: [],
      customeraddres: {
        user_id: "",
        customer_address_id: "",
        alias: "",
        alias_code: "",
        address1: "",
        address2: "",
        zipcode1:"",
        is_default: "",
        latitude:"",
        longitude:"",
        remarks:"",
        country:"",
        state:"",
        city:""
        // city_id: "",
        // area_id: "",
        // zipcode: ""
      },
      custprodctdetail: {
        user_id: "",
        cart_type: "",
        timezone: ""
      },
      getcartdata: "",
      getcartlngth: 0,
      location_details: {
        customer_address_id: "",
        lat: "",
        lng: "",
        address: ""
      },
      searchqry: "",
      iosappurl: "",
      androidappurl: "",
      store_id: '1',
      is_business_model: '0',
      configuration: [],
      brand_logo:"",
      brand_mobile_logo:"",
      current_route_name: '',
      address: "Location",
      profiledata: null,
      check_user: {
        user_id: '',
        token: '',
      },
      appversion:"",
      search_autocomplete:{
        search_query: "",
        store_id:"",
        limit:1,
        page:1
      },
      auto_complete:[],
      auto_complete_result:null,
      modal:false
    };
  },
  computed: {
    ...mapGetters(["custglblsetngs"]),
    avatarText(){
      var str1 = this.profiledata.name.split(" ")[0];
      var str2 = this.profiledata.name.split(" ")[1];
      return str1 + " " + str2;
    }
  },
  mounted() {
    this.appversion = appversion;
    this.originalColor();
    this.current_route_name = this.$route.name;
    this.getGlblesetngs();
    bus.$on("newprofile", data => {
      this.userprofile = JSON.parse(JSON.stringify(data.data[0]));
    });
    bus.$on("new", data => {
      this.userprofile = JSON.parse(JSON.stringify(data));
      this.getProfile();
    });
    bus.$on("getcartlength", data => {
      this.getcartlngth = data;
    });
    bus.$on("globalsetting", data => {
      this.configuration = data
      localStorage.setItem("review_rating_module", JSON.parse(this.configuration).review_rating_module)
      localStorage.setItem("show_review_rating", JSON.parse(this.configuration).show_review_rating)
      localStorage.setItem("use_google_map", JSON.parse(this.configuration).use_google_map)      
      localStorage.setItem("country", JSON.parse(this.configuration).country)
      localStorage.setItem("country_codes", JSON.parse(this.configuration).country_codes)
      localStorage.setItem("default_location_lat", JSON.parse(this.configuration).default_location_lat)
      localStorage.setItem("default_location_long", JSON.parse(this.configuration).default_location_long)
      localStorage.setItem("default_location_text", JSON.parse(this.configuration).default_location_text)
      localStorage.setItem("footer_backgroundcolor", JSON.parse(this.configuration).css_footer)
      localStorage.setItem("footer_copyright_text", JSON.parse(this.configuration).footer_copyright)
      localStorage.setItem("button_default_color",JSON.parse(this.configuration).css_button_customer_default)
      localStorage.setItem("button_hover_color",JSON.parse(this.configuration).css_button_customer_hover)
      localStorage.setItem("terms_of_use_url",JSON.parse(this.configuration).terms_of_use_url)
      localStorage.setItem("store_tax",JSON.parse(this.configuration).store_tax_label)
      localStorage.setItem("delivery_boy",JSON.parse(this.configuration).deliveryboy_label)
      localStorage.setItem("pincode_label",JSON.parse(this.configuration).pincode_label)
      localStorage.setItem("pincode_length",JSON.parse(this.configuration).pincode_length)
      localStorage.setItem("favicon_icon",JSON.parse(this.configuration).favicon_icon)
      localStorage.setItem("css_footer_font",JSON.parse(this.configuration).css_footer_font)
      localStorage.setItem("css_button_customer_default_font",JSON.parse(this.configuration).css_button_customer_default_font)
      localStorage.setItem("is_wholeseller",JSON.parse(this.configuration).allow_wholeseller)
      localStorage.setItem("is_livetracking",JSON.parse(this.configuration).livetracking)
      localStorage.setItem("css_button_customer_hover_font",JSON.parse(this.configuration).css_button_customer_hover_font)
      localStorage.setItem("show_out_of_stock_items", JSON.parse(this.configuration).show_out_of_stock_items);
    });
    if (localStorage.getItem("user") != null) {
      //this.checkUserLogin();
      this.getUserAddress();
    }
    this.iosappurl = localStorage.getItem("customerappurlios");
    this.androidappurl = localStorage.getItem("customerappurlandroid");

    if(localStorage.getItem("location_details") != null)
    {
      this.address = JSON.parse(localStorage.getItem("location_details")).address;  
    }
    window.$('.navbar-toggler').click(function(){
     if(window.$('body').hasClass('sidebar-open'))
     {
       window.$('body').removeClass('sidebar-open')
     }
     else
     {
       window.$('body').addClass('sidebar-open')
     }
   })
   if (window.location.href.indexOf('profile') > -1){
      window.$('body').addClass("top-menu-add-padding")
   }
   else{
     window.$('body').removeClass("top-menu-add-padding")
   }
    if (window.location.href.indexOf('message') > -1 && window.location.href.indexOf('#GiftVoucher') > -1)
    {
      this.$toast.error("Something went wrong while processing your request");
      window.$("#GiftVoucherModal").modal("hide");
    }
    else if(window.location.href.indexOf('result') > -1 && window.location.href.indexOf('result=FAILED') <= -1 && window.location.href.indexOf('#GiftVoucher') > -1)
    {
      this.saveGiftVoucher();
    }
  },
  filters: {
    formatAddress: function(value) {
      if (value != null && value != undefined && value.length > 27) {
        return value.slice(0, 21) + "...";
      }
      return value;
    }
  },
  updated(){
    this.avatarBackgroundColor = this.buttonColor.backgroundColor;
    this.avatarTextColor = this.buttonColor.color;
    window.$('#navbarDropdown .avatar table tbody tr td').css({ color: localStorage.getItem("css_button_customer_default_font")});
  },
  methods: {
    setState(state){
      this.searchqry = state.item_name;
      this.store_id = localStorage.getItem("store_id");
      localStorage.setItem("home-search", this.searchqry);
      this.$router.push({ path: `/shopdetail?storeid=${this.store_id.toString()}` });
    },
    async searchAutoComplete(){
      this.auto_complete = [];
      this.search_autocomplete.search_query = this.searchqry;
      this.search_autocomplete.store_id = localStorage.getItem("store_id");
      this.search_autocomplete.limit = 10;
      this.search_autocomplete.page = 1;
      await this.$store.dispatch("searchautocomplete", this.search_autocomplete).then(response => {
          this.auto_complete_result = response.data;
          this.auto_complete = this.auto_complete_result.filter(data => {
            return data.item_name.toLowerCase();
          });
          if(response.status == 0){
            this.auto_complete = [];    
          }
      });
      //this.auto_complete = [];
    },
    saveGiftVoucher(){
      if(localStorage.getItem("gift_voucher_transaction_request") != null && localStorage.getItem("gift_voucher_transaction_request") != undefined && localStorage.getItem("paymenttransactionid") != null && localStorage.getItem("paymenttransactionid") != undefined)
      {
      var saveGiftVoucher = JSON.parse(localStorage.getItem("gift_voucher_transaction_request"));
      saveGiftVoucher.transaction_id = localStorage.getItem("paymenttransactionid");
        this.$store
        .dispatch("managegiftvoucher", saveGiftVoucher)
        .then(response => {
        window.$('#GiftVoucherModal').modal("hide");
        if (response.status == 0) {
        this.$toast.error(response.message);
        } else {
        this.$toast.success(response.message);
        }
        localStorage.removeItem("gift_voucher_transaction_request")
        localStorage.removeItem("paymenttransactionid")
        });
        }
    },
    headerMenuProfileNavigate(redirectUrl){
      if (window.location.href.indexOf('profile') > -1)
      {
         window.location.href = 'profile#profile';
         window.location.reload();
      }
      else
      {
        window.location.href = redirectUrl;
      }
    },
    redirectToHomePage(){
      window.$('#StoreChangeConfirmationModal').modal('hide')
      this.$router.push("/");
    },
    searchToggle(){
      if(window.$("#Togglesearchbar").toggleClass('show')[0].classList.contains('show') == false && this.searchqry!=''){
        this.searchqry = '';
      }
      setTimeout(() => (this.$refs.header_search.focus()), 1000);
    },
    postSearchnew() {
      document.body.classList.remove('top-menu-add-padding');
      if(this.searchqry != ""){
        if(this.is_business_model == 3){
          localStorage.setItem("home-search", this.searchqry);
          this.store_id = localStorage.getItem("store_id");
          if(this.$route.name == 'shopdetail'){
            window.location = '/shopdetail?storeid='+this.store_id;
          } else {
            this.$router.push({ path: `/shopdetail?storeid=${this.store_id.toString()}` });
          }
        } else {
          localStorage.setItem("searchqry", this.searchqry);
          this.$router.push({ name: "search" });
        }
      }
    },
    getProfile() {
      this.profile.user_id = (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).user_id : "0";
      this.$store.dispatch("profiledetails", this.profile).then(response => {
        if(response.status == 1)
        {
          this.profiledata = JSON.parse(JSON.stringify(response.data));
          this.userprofile.name = this.profiledata.name;
          this.userprofile.email = this.profiledata.email;
          this.userprofile.user_picture = this.profiledata.user_picture;
        }
      });
    },
    getCartDetails(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        document.body.classList.remove('top-menu-add-padding');
        this.$router.push("/cart");
      }
    },
    async getGlblesetngs() {
      return this.$store.dispatch("getglobalsetng").then(response => {
        if(response.status == 1){
          this.globalsettings = response.data;
          localStorage.setItem("global_settings", JSON.stringify(this.globalsettings));
          bus.$emit("globalsetting", JSON.stringify(this.globalsettings));
          localStorage.setItem("currency_symbol", this.globalsettings.currency_symbol);
          localStorage.setItem("timezone", this.globalsettings.timezone);
          localStorage.setItem("customerappurlandroid", this.globalsettings.customer_app_url_android);
          localStorage.setItem("customerappurlios", this.globalsettings.customer_app_url_ios);
          localStorage.setItem("businessappurlandroid", this.globalsettings.business_app_url_android);
          localStorage.setItem("businesssappurlios", this.globalsettings.business_app_url_ios);
          localStorage.setItem("walletmaxamount", this.globalsettings.max_payment);
          localStorage.setItem("date_format", this.globalsettings.date_format_web);
          localStorage.setItem("customer_app_short_url", this.globalsettings.customer_app_short_link);
          localStorage.setItem("is_livetracking", this.globalsettings.livetracking);
          localStorage.setItem("payment_gateway_type", this.globalsettings.payment_gateway_type);
          this.is_business_model = this.globalsettings.business_model;
          localStorage.setItem("is_business_model",this.globalsettings.business_model);
          localStorage.setItem("offer_color_code",this.globalsettings.offer_color_code);
          localStorage.setItem("max_wallet_transfer_allow", this.globalsettings.max_transfer);
          localStorage.setItem("transfer_charge", this.globalsettings.transfer_charge);
          localStorage.setItem("transfer_charge_type", this.globalsettings.transfer_charge_type);
          localStorage.setItem("is_transfer_chargable", this.globalsettings.is_transfer_chargable);
          localStorage.setItem("is_credit_chargable", this.globalsettings.is_credit_chargable);
          localStorage.setItem("credit_charge_type", this.globalsettings.credit_charge_type);
          localStorage.setItem("credit_charge", this.globalsettings.credit_charge);
          localStorage.setItem("allow_credit", this.globalsettings.allow_credit);
          localStorage.setItem("min_credit", this.globalsettings.min_credit);
          localStorage.setItem("customer_location_type",this.globalsettings.customer_location_type)
          localStorage.setItem("min_credit", this.globalsettings.min_credit);
          localStorage.setItem("default_rating_bg_color", this.globalsettings.default_rating_color_code_bg);
          localStorage.setItem("default_rating_text_color", this.globalsettings.default_rating_color_code_text);
          localStorage.setItem("show_rating_different_color_code", this.globalsettings.show_rating_different_color_code);
          localStorage.setItem("alternet_bg_color", this.globalsettings.alternate_color_code_bg);
          localStorage.setItem("alternate_color_code_text", this.globalsettings.alternate_color_code_text);
          localStorage.setItem("show_five_star_rating", this.globalsettings.show_rating);
          localStorage.setItem("pincode_length", this.globalsettings.pincode_length);
          localStorage.setItem("show_out_of_stock_items", this.globalsettings.show_out_of_stock_items);
          func.changeFavicon(this.globalsettings.favicon_icon);
        } else {
          localStorage.setItem("footer_backgroundcolor", localconfig.css_footer)
          localStorage.setItem("footer_copyright_text", localconfig.footer_copyright)
          localStorage.setItem("button_default_color",localconfig.css_button_customer_default)
          localStorage.setItem("button_hover_color",localconfig.css_button_customer_hover)
          localStorage.setItem("terms_of_use_url",localconfig.terms_of_use_url)
          localStorage.setItem("store_tax",localconfig.store_tax_label)
          localStorage.setItem("delivery_boy",localconfig.deliveryboy_label)
          localStorage.setItem("pincode_label",localconfig.pincode_label)
          localStorage.setItem("favicon_icon",localconfig.favicon_icon)
          localStorage.setItem("css_footer_font",localconfig.css_footer_font)
          localStorage.setItem("css_button_customer_default_font",localconfig.css_button_customer_default_font)
          this.brand_logo = localconfig.logo
          this.brand_mobile_logo = localconfig.mobile_logo
          localStorage.setItem("timezone", localconfig.timezone);
          localStorage.setItem("currency_symbol", localconfig.currency_symbol);
          localStorage.setItem("css_button_customer_hover_font", localconfig.css_button_customer_hover_font);
          localStorage.setItem("date_format", localconfig.date_format_web);
          localStorage.setItem("customer_app_short_url", localconfig.customer_app_short_link);
          localStorage.setItem("is_livetracking", localconfig.livetracking);
          localStorage.setItem("pincode_length", localconfig.pincode_length);
          localStorage.setItem("show_out_of_stock_items", localconfig.show_out_of_stock_items);
        }
        
      });
    },
    Deliveraddrs(event) {
      var valObj = this.getaddressdetails.data.filter(function(elem) {
        if (event.target.value == elem.customer_address_id) return elem;
      });
      this.customeraddres.user_id = (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).user_id : "0";
      this.customeraddres.customer_address_id = valObj[0].customer_address_id;
      localStorage.setItem("updated_address_id",valObj[0].customer_address_id);
      this.customeraddres.is_default = "1";
      this.customeraddres.alias = valObj[0].alias;
      this.customeraddres.alias_code = valObj[0].alias_code;
      this.customeraddres.address1 = valObj[0].address;
      this.customeraddres.address2 = valObj[0].address2;
      this.customeraddres.country = valObj[0].country_name1;
      this.customeraddres.state = valObj[0].state_name1;
      this.customeraddres.city = valObj[0].city_name1;
      this.customeraddres.zipcode1 = valObj[0].zipcode1;
      this.customeraddres.latitude = valObj[0].latitude;
      this.customeraddres.longitude = valObj[0].longitude;
      // this.customeraddres.area_id = valObj[0].area_id;
      // this.customeraddres.city_id = valObj[0].city_id;
      // this.customeraddres.zipcode = valObj[0].zipcode;

      this.$store
        .dispatch("managecustomeraddrs", this.customeraddres)
        .then(() => {

          this.location_details.customer_address_id = valObj[0].customer_address_id;
          this.location_details.lat = valObj[0].lat;
          this.location_details.lng = valObj[0].long;

          var area_name = valObj[0].area_name1 + ', ';
          var area_id = valObj[0].area_id1;
          var zipcode = valObj[0].zipcode1;
          var city_name = valObj[0].city_name1 + ' ' + valObj[0].zipcode1 +', ';
          var state_name = (valObj[0].state_name1 != '') ? valObj[0].state_name1 + ', ' : '';
          var country = (valObj[0].country_name1 != '') ? valObj[0].country_name1 : '';
          this.location_details.address = area_name + city_name + state_name + country;
          if (valObj[0].lat != "0" && valObj[0].lng != "0")
          {
            if (localStorage.getItem("customer_location_type") == "3"){
              this.location_details.address = valObj[0].zipcode;
            }
            else if (localStorage.getItem("customer_location_type") == "2"){
              this.location_details.address = area_name +", "+city_name;
            }
            localStorage.setItem("location_details", JSON.stringify(this.location_details));
            localStorage.setItem("config_area_id", area_id);
            localStorage.setItem("config_customer_pincode", zipcode);
          }
          localStorage.setItem("address_changed","1");
          localStorage.setItem("address_set_type","1")
          this.getUserAddress();
          if (this.is_business_model == 3 && window.location.href.indexOf("productdetail") <= 0 && window.location.href.indexOf("shopdetail") <= 0)
             window.location.reload();
          else if (this.is_business_model != 3)
             window.location.reload();
        });
    },
    getFilteredUserAddress(user){
      var tmp_user_address = [];
      tmp_user_address.push(user.address2, user.address, user.area_name, user.city_name, user.state_name, user.zipcode);
      var filtered_user_address = tmp_user_address.filter(function (el) { return el; });
      
      if(filtered_user_address.length > 0){
        return filtered_user_address.join(', ');
      } else {
        return "";
      }
    },
    getUserAddress() {
      
      this.profile.user_id = (localStorage.getItem("user") != null) ? JSON.parse(localStorage.getItem("user")).user_id : "0";
      this.$store.dispatch("customeraddress", this.profile).then(response => {
        this.getaddressstatus = response.status;
        this.getaddressdetails = response;

        if(this.getaddressstatus == 0){
          return false;
        }

        var valObj = this.getaddressdetails.data.filter(function(elem) {
          if (elem.is_default == "1") return elem.customer_address_id;
        });

        if(valObj != undefined && valObj != null && valObj.length > 0 && this.is_business_model==3)
        {
           if (parseInt(valObj[0].nearest_store_id) != parseInt(localStorage.getItem("store_id")) && localStorage.getItem("address_changed") != undefined && localStorage.getItem("address_changed") != null)
            {
              if (window.location.href.indexOf("productdetail") > -1 || window.location.href.indexOf("shopdetail") > -1)
               {
                 if (window.$('#StoreChangeConfirmationModal').length > 0)
                  {
                    window.$('#StoreChangeConfirmationModal').modal('show')
                    localStorage.removeItem("address_changed")
                  }
                }
            }
        }

        if(localStorage.getItem("is_login") != null)
        {
          this.location_details.customer_address_id = valObj[0].customer_address_id;
          this.location_details.lat = valObj[0].lat;
          this.location_details.lng = valObj[0].long;

          // var area_name = valObj[0].area_name + ', ';
          // var city_name = valObj[0].city_name + ' ' + valObj[0].zipcode +', ';
          // var state_name = (valObj[0].state_name != '') ? valObj[0].state_name + ', ' : '';
          // var country = (valObj[0].country_name != '') ? valObj[0].country_name : '';
          var area_name = valObj[0].area_name1 + ', ';
          var city_name = valObj[0].city_name1 + ' ' + valObj[0].zipcode1 +', ';
          var state_name = (valObj[0].state_name1 != '') ? valObj[0].state_name1 + ', ' : '';
          var country = (valObj[0].country_name1 != '') ? valObj[0].country_name1 : '';
          this.location_details.address = area_name + city_name + state_name + country;
          if (valObj[0].lat != "0" && valObj[0].lng != "0")
            localStorage.setItem("location_details", JSON.stringify(this.location_details));
          localStorage.removeItem("is_login");
          localStorage.setItem("is_login_searchbar",1)
        }

        if (localStorage.getItem("first_time_signup") != null)
        {
          this.$toast.success("Congratulations!! You have successfully registered with us. Happy Shopping!!");
          localStorage.removeItem("first_time_signup")
        }

        this.seldefltaddrs = (JSON.parse(localStorage.getItem("location_details")).customer_address_id != undefined) ? JSON.parse(localStorage.getItem("location_details")).customer_address_id : valObj[0].customer_address_id;

      });
     
    },
    openLoginfrm() {
      localStorage.setItem("LastUrlBeforeLogin",window.location.href);
      window.$("#login-form").modal("show");
      window.$("#VerificationFields").removeClass("show");
      window.$("#SignupFields").removeClass("show");
      window.$("#ChangePassword").removeClass("show");
      window.$("#loginPopupFields").addClass("show");
    },
    openSignupfrm() {
      window.$("#login-form").modal("show");
      window.$("#loginPopupFields").removeClass("show");
      window.$("#ChangePassword").removeClass("show");
      window.$("#SignupFields").addClass("show");
    },
    getCart() {
      this.custprodctdetail.user_id = (localStorage.getItem("user") != null)
            ? JSON.parse(localStorage.getItem("user")).user_id
            : "0";
      this.custprodctdetail.cart_type = 1;
      this.custprodctdetail.timezone = "+6";
      this.$store
        .dispatch("getcartdetail", this.custprodctdetail)
        .then(response => {
          this.getcartdata = JSON.parse(JSON.stringify(response));
          if(this.getcartdata != null && this.getcartdata.status != 0){
            this.getcartlngth = this.getcartdata.data.cart_details.quantity;
          }
        });
    },
    logout() {
      if (localStorage.getItem("user") != null) {
        window.$('body').removeClass('sidebar-open')
        this.logoutbody.phone = JSON.parse(localStorage.getItem("user")).phone;
        this.logout.fcm_token = localStorage.getItem("fcm_token");
        this.$store.dispatch("logout", this.logoutbody).then(response => {
          if (response.status == "1") {
            bus.$emit("logout", false);
            this.firebaseLogOut();
            window.location.href="/";
          } 
        });
      }
    },
    firebaseLogOut(){
      firebase.auth().signOut().then((user) => {
        console.log("user", user);
      }).catch((error) => {
        // An error happened.
        console.log(error);
      });

    },
    isMobile(){
      if(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
        return true;
      } else {
        return false;
      }
    },
    openKhata(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        window.$('body').removeClass('sidebar-open')
        this.$root.$emit('bv::toggle::collapse', 'navbarSupportedContent');
        this.$router.push("/profile#my-khata");
      }
    },
    openWallet(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        document.body.classList.remove('sidebar-open');
        this.$root.$emit('bv::toggle::collapse', 'navbarSupportedContent');
        this.$router.push("/profile#wallet");
      }
    },
    openWishlist(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        document.body.classList.remove('sidebar-open');
        this.$root.$emit('bv::toggle::collapse', 'navbarSupportedContent');
        this.$router.push("/profile#wishlist");
      }
    },
    openChat(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        window.$('body').removeClass('sidebar-open')
        this.$root.$emit('bv::toggle::collapse', 'navbarSupportedContent');
        this.$router.push("/chat");
      }
    },
    openMyOrder(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        window.$('body').removeClass('sidebar-open')
        this.$root.$emit('bv::toggle::collapse', 'navbarSupportedContent');
        this.$router.push("/profile#my-order");
      }
    },
    openHomePage(){
      window.$('body').removeClass('sidebar-open')
        this.$root.$emit('bv::toggle::collapse', 'navbarSupportedContent');
        this.$router.push("/");
    },
    openFavoriteStore(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        window.$('body').removeClass('sidebar-open')
        this.$root.$emit('bv::toggle::collapse', 'navbarSupportedContent');
        this.$router.push("/search#favorite-store");
      }
    },
    openAddressBook(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        window.$('body').removeClass('sidebar-open')
        this.$root.$emit('bv::toggle::collapse', 'navbarSupportedContent');
        this.$router.push("/profile#address-book");
      }
    },
    openGiftVoucher(){
      window.$("#GiftVoucherModal").modal("show");
    },
    openContactUs(){
      window.$("#ContactUsModal").modal("show");
    },
    openNotification(){
      if(localStorage.getItem("user") == null){
        this.openLoginfrm();
      } else {
        window.$('body').removeClass('sidebar-open')
        this.$root.$emit('bv::toggle::collapse', 'navbarSupportedContent');
        this.$router.push("/profile#notification");
      }
    },
    checkUserLogin(){
      var user = JSON.parse(localStorage.getItem("user"));
      this.check_user.user_id = user.user_id;
      this.check_user.token = user.token;
      this.$store.dispatch("checkUserToken", this.check_user).then(response => {
        
        if (response.status == 0) { //Invalid user token - logout user
          //this.logout();
        }

      });
    },
    openNewsLetter(){
      this.$router.push("/newsletter");
    },
    openModal(){
      window.$("#WhatsNew").modal("show");
    },
    clickFaq(){
      this.$router.push("/faq");

    }
  }
};
</script>
<style scoped>
.position-fixed {
  top:0;left: 0;right: 0;z-index: 999;
}
</style>